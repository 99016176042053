<template>
	<v-menu
		bottom
		left
		offset-y
		origin="top right"
		transition="scale-transition"
		:close-on-content-click="closeOnClick"
	>
		<template v-slot:activator="{ attrs, on }">
			<v-list-item v-bind="attrs" v-on="on" v-if="menuUser">
				<v-icon>mdi-palette</v-icon
				><span class="mt-1 ml-1">Estilo</span></v-list-item
			>

			<v-btn
				class="ml-2"
				min-width="0"
				text
				v-bind="attrs"
				v-on="on"
				v-if="!menuUser"
			>
				<v-icon>mdi-palette</v-icon>
			</v-btn>
		</template>

		<v-card class="text-center mb-0 mt-0" width="300">
			<v-card-text>
				<strong class="mb-3 d-inline-block">Color Principal</strong>

				<v-item-group v-model="color">
					<v-item v-for="color in colors" :key="color" :value="color">
						<template v-slot="{ active, toggle }">
							<v-avatar
								:class="active && 'v-settings__item--active'"
								:color="color"
								class="v-settings__item"
								size="25"
								@click="toggle"
							/>
						</template>
					</v-item>
				</v-item-group>

				<v-divider class="my-4 secondary" />

				<strong class="mb-3 d-inline-block">Color de Menu</strong>

				<v-item-group v-model="colorMenu">
					<v-item v-for="color in colorsMenu" :key="color" :value="color">
						<template v-slot="{ active, toggle }">
							<v-avatar
								:class="active && 'v-settings__item--active'"
								:color="color"
								class="v-settings__item"
								size="25"
								@click="toggle"
							/>
						</template>
					</v-item>
				</v-item-group>

				<v-divider class="my-4 secondary" />

				<v-row align="center" no-gutters>
					<v-col cols="auto"> Modo Dark </v-col>

					<v-spacer />

					<v-col cols="auto">
						<v-switch
							v-model="$vuetify.theme.dark"
							class="ma-0 pa-0"
							color="secondary"
							hide-details
						/>
					</v-col>
				</v-row>

				<v-divider class="my-4 secondary" />

				<v-row align="center" no-gutters>
					<v-col cols="auto">Imagen en Menu </v-col>

					<v-spacer />

					<v-col cols="auto">
						<v-switch
							v-model="showImg"
							class="ma-0 pa-0"
							color="secondary"
							hide-details
						/>
					</v-col>
				</v-row>

				<v-divider class="my-4 secondary" />

				<strong class="mb-3 d-inline-block">Imagenes para Menu</strong>
 
				<v-item-group
					v-model="image"
					class="d-flex justify-space-between mb-3"
					style="overflow-x: scroll"
				>
					<v-item
						v-for="image in images"
						:key="image"
						:value="image"
						class="mx-1"
					>
						<template v-slot="{ active, toggle }">
							<v-sheet
								:class="active && 'v-settings__item--active'"
								class="d-inline-block v-settings__item"
								@click="toggle"
							>
								<v-img :src="image" height="100" width="50" />
							</v-sheet>
						</template>
					</v-item>
				</v-item-group>
			</v-card-text>
		</v-card>
	</v-menu>
</template>

<script>
	// Mixins
	import Proxyable from "vuetify/lib/mixins/proxyable";
	import { mapMutations, mapState } from "vuex";

	export default {
		name: "DashboardCoreSettings",

		mixins: [Proxyable],
		props: {
			menuUser: {
				type: Boolean, // true: si es un submenu del boton "user" , false: si es es un boton del appbar
			},
		},
		data: () => ({
			closeOnClick: false,
			color: "#E91E63",
			colorMenu: "#E91E63",
			colors: ["#9C27b0", "#00CAE3", "#4CAF50", "#ff9800", "#E91E63", "#FF5252"],
			colorsMenu: [
				"rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)",
				"rgba(28, 26, 26, 1), rgba(0, 0, 0, 0.7)",
			],
			colors: ["#9C27b0", "#00CAE3", "#4CAF50", "#ff9800", "#E91E63", "#FF5252"],
			image: "/img/sidebar-5.jpg",
			images: [
				"",
				"/img/sidebar-5.jpg",
				"/img/sidebar-6.jpg",
				"/img/sidebar-7.jpg",
				"/img/sidebar-1.jpg",
				"/img/sidebar-2.jpg",
				"/img/sidebar-3.jpg",
				"/img/sidebar-4.jpg",
			],
			menu: false,
			saveImage: "",
			showImg: true,
		}),

		computed: {
			...mapState(["barImage", "barColor"]),
		},

		watch: {
			color(val) {
				this.$vuetify.theme.themes[this.isDark ? "dark" : "light"].primary = val;
			},
			colorMenu(val) {
				this.setBarColor(val);
				console.log("this.$store.state.barColor");
				console.log(this.$store.state.barColor);
			},

			showImg(val) {
				if (!val) {
					this.saveImage = this.barImage;
					this.setBarImage("#");
				} else if (this.saveImage) {
					this.setBarImage(this.saveImage);
					this.saveImage = "";
				} else {
					this.setBarImage(val);
				}
			},
			image(val) {
				this.setBarImage(val);
			},
		},

		methods: {
			...mapMutations({
				setBarImage: "SET_BAR_IMAGE",
				setBarColor: "SET_BAR_COLOR",
			}),
		},
		mounted: function () {
			this.colors.unshift(this.$vuetify.theme.themes.dark.primary);
		},
	};
</script>

<style lang="sass">
.v-settings
  .v-item-group > *
    cursor: pointer

  &__item
    border-width: 3px
    border-style: solid
    border-color: transparent !important

    &--active
      border-color: #00cae3 !important
</style>
